﻿/**
 *  @ngdoc controller
 *  @name Loader Directive
 *  @description Loader Directive
 
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('loading', function ($rootScope) {
        return {
            restrict: 'E',
            replace: true,
            template: `<div class="spinnersContainer" ng-if="isLoader">
                          <img src="../assets/img/loader/loader.gif" alt="loading..." class="loaderGif" />
                       </div>`,
            link: function (scope, element) {
                // Applying base class to the element
                angular.element(element).css('display', 'none');
                scope.isLoader = false;

                // Listening to 'globalLoadingStart' event fired by interceptor on request sending
                scope.$on('globalLoadingStart', function () {
                    //angular.element(element).css('display', 'block');
                    scope.isLoader = true;
                    $rootScope.$broadcast('removecreateoptionmaster');
                });

                // Listening to 'globalLoadingEnd' event fired by interceptor on response receiving
                scope.$on('globalLoadingEnd', function () {
                    //angular.element(element).css('display', 'none');
                    scope.isLoader = false;

                    /*commented this code by shwetha, it was affecting order,customer edit also.*/
                    $rootScope.$broadcast('removeeditoptionmaster');
                   // $rootScope.$broadcast('removecreateoptionmaster');
                    
                    
                });


                //21st Oct 2020.*******
                //This event will be called on loader hide, clear search, close search text in grids of masters. 
                //Done by shashank // Changes are done in all masters.
                $rootScope.$on('removeeditoptionmaster', function () {
                    if ($rootScope.userName != 'admin@voptics.com') { // remove edit icon for all masters except for admin@voptics.com //done by shashank. 
                       
                        $rootScope.hideIcon = true;
                        setTimeout(function () {
                            $rootScope.$evalAsync(function () {
                                const elements = document.getElementsByClassName("fa fa-pencil mst");
                                while (elements.length > 0) {
                                    elements[0].remove();
                                }
                                

                            });
                        }, 0) // digest cycle will go zero, when timeout is 0
                    }
                    else
                        $rootScope.hideIcon = false;
                });
                //End of Shashank******

                //02nd Jan 2023.*******
                //This event will be called on loader hide, clear search, close search text in grids of masters. 
                //Done by priyanka // Changes are done in all masters.
                $rootScope.$on('removecreateoptionmaster', function () {
                    if ($rootScope.userName != 'admin@voptics.com') { // remove create icon for all masters except for admin@voptics.com //done by priyanka. 

                        $rootScope.hideIcon = true;
                       // setTimeout(function () {
                            $rootScope.$evalAsync(function () {
                                const elements = document.getElementsByClassName("custFab mst");
                                while (elements.length > 0) {
                                    elements[0].remove();
                                }


                          });
                      //  }, 0) // digest cycle will go zero, when timeout is 0
                    }
                    else
                        $rootScope.hideIcon = false;
                });
                //End 
            }
        }
    })
}(window.angular));